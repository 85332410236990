/* .App {
   text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'OpenSans','Tahoma', 'Geneva', 'Roboto medium', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.csslogo {
 background: #ffc107;
 width: 80px;
 height: 80px;
 border-radius: 50%;
 text-align: center;
 margin-left: 35%;
}

.csslogo::before {
  font-weight: 700;
  font-size: 50px;
  color: rgb(18, 18, 19);
  content: "L";
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'OpenSans','Tahoma', 'Geneva', 'Roboto medium', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ver-hori-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  height: 50%;
  width: 60%;
 
}

.ldiv {
  width: 68%;

}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

* {
  box-sizing: border-box;
}


.flg {
  /* left: 0;
  position: absolute;
  top: calc(50% - 0.5em);
  width: 30px; */
  margin-right: 4px;
}

.btmimg { /*height: 53px;*/
  width:140px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-right: 20px;}

.trbadge:hover {
  background-color: #e0b90b;
}

.addrdiv {
margin-top: 5px;
    /* padding: 2px; */
    display: block;
}
.lbl-primary{
background-color: #1d5481;
color: white;
max-width: fit-content;
padding: 5px;
font-size: 14px;
}
.heading {
font-size: 20px;
margin-right: 10px;
}

.amain {
  font-weight: 600;
}

.amain, .header {
  font-size: 19px;
}

.badge:hover {
  background-color: #6d6d6d;
}

.srbadge:hover {
  background-color: #1d1717;
}
.srbadge{
  background-color:#4b4b4b;
  color:white;
  float: left;
    /* display: inline-block; */
    /* overflow: hidden; */
    /* align-items: self-start; */
    padding: 5px;
    margin-right: 10px;
    margin-top: 10px;
}
.phweb{ margin-right: 5px; }

.page-link { background-color: #a5d7e7}
.page-link:hover { background-color: #022bb4; color: white;}

.navmain { max-height: 120px;}
.sbtn { max-width: 160px; width: auto; }
.img-logo {
width: 150px;
margin-left: -35px;
/* padding-right: 25px; */
}
.logo{
  font-size: 35px;
  font-weight: 400;
  font-family: 'Segoe UI', 'OpenSans', Tahoma, 'Roboto',Geneva, Verdana, sans-serif;
  /* margin-right: 30px; */
  text-align: left;
  /* margin-bottom: 1px; */
  /* padding-bottom: 8px; */
  padding-right: 30px;
  text-shadow: 0 0 black;
  /* width: 150px; */
  /* background-image: url("https://static-law.s3.us-west-2.amazonaws.com/lawyerz-logo-wide-100.png"); */
}

div {
  flex-shrink: 1;
}

h3 { font-size:16px; }

.ph-outer {
  display: flex;
}
.phdiv {
  background-color: #f9f9f9;
  color:#000000;
  display: flex;
  overflow:scroll;
  margin-left:12px;
  /* padding:2px; */
  align-items: flex-end;
  /* border-bottom-right-radius: 5px; */
  /* border-top-color: #800200; */
  /* border-bottom-left-radius: 5px; */
  /* border-top-width: 5px; */
  word-wrap: break-word;
  text-align: center;
}

/* scrollbar */

/* width */
::-webkit-scrollbar {
  height: 5px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(54, 53, 53); 
  border-radius: 3px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  
  background:#1d5481;
  border-radius: 3px;
  height: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(39, 39, 39); 
}


/* .phdiv:hover { background-color: #e9e9e9; } */
.phname {
  display:block; text-align:center;
  align-items: top;
}
.phnum {
  margin-top: 2px;
  margin-bottom: 5px;
  margin-right: 5px;
  display: block;
  text-align: center;
}

#qry:before {
  content: "•";
  font-size: 18px;
  font-weight:900;
  color: #0d6efd;
  text-shadow: #0d3bd3 0 0 3px;
  margin:0 8px;
}

.block { display: inline-block;}
.spacer { flex-grow: 1; }
.break { flex-basis: 100%; height:0;}
.sbox-out {

  display: inline-block;
  /* margin-top:7px; */
  width: auto;
  min-width: 225px;
  margin-right: auto;
}

.ftrimgdiv { background-color:#ffc107; padding:2px; border-bottom: #1d5481;
  border-bottom-style:solid; border-bottom-width: 2px; padding-top:4px; float: right; 
  width: 100%; margin-top:14px;
}

.ftrdiv { text-align: right; display: flex; margin-right: 5px; float: right;  
}

.pnl { font-weight: 700;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'OpenSans', 'Tahoma', 'Geneva', Verdana, sans-serif; }
.phitemdiv {
 /* margin-right: 3px; */
  word-wrap: break-word;
  text-align: left;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  /* align-items: center; */
  text-align: left;
  vertical-align: top;
  min-width: 140px;
  min-height: 75px;
  margin-right: 4px;
  /* margin-bottom: 5px; e9ecef*/
  justify-content:space-between; 
  flex-direction: column;
  background-color: #fcfbfc;
  border-bottom-color: black;
  /* border-bottom-width: 5px; */
  border-bottom-style: inset;
  /* border-block-color: inherit; */
  /* border-top: coral; */
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-bottom: 8px;
}
.phitemdiv:hover {
  /* font-size:16px;  */
  /* width: 145x;
  height: 130px; */
  background-color:#f5f5f5; 
   border-style: outset;  
  cursor: pointer; 
  /*#c5d5f9 */
  /* padding:5px; */
  /* font-weight: 700; */
 /* margin-left:5px; 
margin-right: 5px; */
}

.phnum:hover {
  /* font-size:14px; */
   font-weight:900;
}
.trbtn {
  padding:0px;
  margin-left:3px;
  font-size:14px;
}

.nmsg{ font-size: 16px; font-family: -apple-system, BlinkMacSystemFont,'Segoe UI', 'OpenSans', 'Tahoma', 'Geneva', Verdana, sans-serif; 
padding: 2px; margin: 2px;
}
 .bmsg{ /*font-size: 16px; */
  font-family: -apple-system, BlinkMacSystemFont,'Segoe UI', 'OpenSans', 'Tahoma', 'Geneva', Verdana, sans-serif;  
  padding: 2px; font-weight: 640;
  }

.mtop { margin-bottom: 20px;}
.trbadge {
background-color:#ffc107;
color:rgb(0, 0, 0);
float: left;
  /* display: inline-block; */
  /* overflow: hidden; */
  /* align-items: self-start; */
  padding: 1px;
    margin-right: 8px;
    margin-top: 10px;
    border-radius: 5px;
}


.addr {
  margin-bottom: 8px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
}

.trdiv{
  margin-left: 10%;
  width: 450px;
}


.topdiv {
  width: 95%;
  min-height: 350px;
}
.asub {
  font-size: 16px;
  font-weight: 500;
}

.hdr {
  font-size: 30px;
  font-weight: 600;
  line-height: 120%;
  margin-top: 10px;
  margin-bottom: 15px;
}

.sboxdiv {
  margin-left: 15%;
  margin-top: 5px;
}

.rsdiv {
  /* align-items: center; */
  margin-left: 180px;
  max-width: 850px;
  min-height: 250px;
}
@media (max-width: 1300px) {
.rsdiv {   margin-left: 180px; }
}
@media (max-width: 1121px) {
  .rsdiv {   margin-left: 180px; }
}

.topmast { height: 4px; background-color: #800200;}
.tags {display: inline-block; padding:5px}
.pwdiv { display: flex;}
.crd {
  border: 1px solid rgba(170, 142, 142, 0.125);
  padding: 0;
  margin-bottom: 2px;
}
.crditem { padding: 1px; }
.sbox {
  width: 60%;
  float: left;
  margin-right: 25px;
  font-size: 16px;
  font-weight: 500;
  border: #282c34 1px;
  /* margin-left: 8%; */
}

.imdiv { align-items: center;
  display: flex;}

.tline {
  display: flex;
  margin-bottom: 3px;
}
.hdrdiv {
  padding: 2px;
  margin-left: 3px;
  margin-top: 5px;
  height: fit-content;
  width: fit-content;
}
.rdiv{
  width: 32%;
}

.trtags { display: inline-block;
  margin-left: 20%;
  margin-top:10px; }

.text-left { text-align: left !important;}
.avatar {
  vertical-align: middle;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
h4 {
  font-size:21px;
}

.badge {padding: 3px; }

.rtgtxt { font-weight: 400; margin-top:2px;}

/* ratings styles */


.checked {
  color: orange;
}

.blchk { color: rgb(72 70 70); }
/* Three column layout */
.side {
  float: left;
  width: 20%;
  margin-top:0px;
  font-weight: 500;
}

.side-right {
  float: left;
  width: 25%;
  margin-top:0px;
  text-align: right;
  font-weight: 500;
}

.middle {
  margin-top:8px;
  float: left;
  width: 55%;
}

/* Place text to the right */
.right {
  text-align: right;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* The bar container */
.bar-container {
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  color: white;
}

.bardiv { display: block; font-size:12px;}
/* Individual bars */
.bar-5 {width: 62%; height: 5px; background-color: #198754;}
.bar-4 {width: 46%; height: 5px; background-color: #0d6efd;}
.bar-3 {width: 36%; height: 5px; background-color: #0dcaf0;}
.bar-2 {width: 26%; height: 5px; background-color: #ff9800;}
.bar-1 {width: 20%; height: 5px; background-color: #bb2d3b;}

.sbox-nav {

  /* width: 80%; */
  /* width:325px; */
  float: left;
  /* margin-right: 10px;
  margin-left:15px; */
  margin-right: 5px;
  font-size: 16px;
  font-weight: 500;
  border: 2px #282c34;
  /* margin-bottom: 10px; */
  min-width: 360px;
  width: max-content;
}

.klink {
	  display: inline-block!important;
  font-weight: 300;

/* background-color: transparent!important; 
  border: 0!important;
  color: blue!important;
  cursor: pointer!important; 
  margin: 2px!important; */
  /* outline: none!important; */
  /*padding: 5px!important;*/
  /* text-decoration: underline!important; */
  /*font-size: 24px;*/
  /* line-height: 120%; */
} 

.klink:hover {
text-decoration: underline!important;
/*font-weight: 800;
font-size: 35px;
background-color: #dbefff;
color: #002161;*/
}

.navitem {font-size:16px; font-weight: 600; padding: 0px; }

.logo:hover, .navitem:hover { font-weight:700; }

.rounded-pill { margin-right: 2px; margin-bottom: 2px; background-color: #515a62 !important; }
.rounded-pill:hover {background-color: #1d1717;}

.abtdiv {
  width:85%;
  margin:5%;
  text-align: left;
 padding: 20px;
 word-wrap: break-word;
 }
.abt {
  width:90%;
  word-wrap: break-word;
  word-spacing: inherit;
  letter-spacing: inherit;
  margin: 15px;
  font-size: 18px;
  line-height: 1.75em;
  padding: 15px;
  text-align: left;}
.nopad { padding: 0px; margin: 0px;}
  .ftr-nav-link { margin-right: 10px; padding:1px;}
  .ftrnavitem { padding:2px; text-align: right; margin-right: 10px; margin-top:5px;}
  
  .wldiv { margin:0px; padding:3px;
    background-color:#1d5481;}
  .wlmsg { color:#f1f1f1 ;  margin-left: 5px; margin-right: 25px;}
  .wlmsg-link {
    color:#f0e5e5;
    font-family: -apple-system, BlinkMacSystemFont,'Segoe UI', 'OpenSans', 'Tahoma', 'Geneva', Verdana, sans-serif;  
  padding: 2px; font-weight: 640;
  }
  .wlmsg-link:hover {
    color:#fff4f4; }

  .wlcls { font-weight: 800; }

  .ftrnavitem:hover { font-weight: 700;}


  
@media screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1200px) {
.rsdiv { margin-left: 5%; }
.topdiv { min-height: 545px; }
.trtags { margin-left: 10%; }
}

@media screen 
  and (min-device-width: 1200px) {
.topdiv { min-height: 600px; }
.trtags { margin-left: 15%; }

}

/*.klink { 
border-bottom: 3px solid #1e2f45 !important;
border-radius: 5px;
background-color: #dbe5fb !important;
margin: 5px !important;
font-size: 21px; 
}*/

.klink:hover {

font-weight: initial !important;
font-size: initial !important;
}
.phitemdiv:hover {
border-radius: 5px;
border-width: 1px;
border-style: solid;  
}
@media screen and (max-device-width: 767px) {
.klink { 
 margin: 4px!important;
  /* outline: none!important; */
  padding: 3px!important;
  /* text-decoration: underline!important; */
  font-size: 18px; 
border-bottom: 2px solid #1e2f45 !important;

}
}



@media screen and (min-width: 300px) and (max-width: 767px) {
  body {
    font-size:12px;
  }

.ftrdiv { margin-right: 0px;}  
.topdiv {
  width: 98%;
  /* min-height: 350px; */
}

  .img-logo { width:100px; margin-left: -20px;}
 .btmimg { width:90px; padding-right: 0px; }

   .rsdiv { margin-left: 5%;}
    h4 {
      font-size:16px;
    }

    #qry:before { font-size: 16px; }


    .avatar { width: 35px; height: 35px;}

    .amain, .header {
      font-size: 16px;
    font-weight: 700;
    }

    .addr {
      font-size: 12px;
    }
    .asub {
      font-size: 13px;
      font-weight: 600;
    }
    .lbl-primary {
      font-size: 12px;
    }
    .rdiv {
      font-size:12px
    }
    .badge {
      font-size: 11px;
      display: block;
      padding: 1px;
      margin-left: 0px;
    }
    .addrdiv { display: inline-block; margin-top: 2px;}
    .fa { font-size: 14px; }
  .sbox-page {
      width: 55%;
      float: left;
      margin-right: 25px;
      font-size: 12px;
      font-weight: 500;
      border: 1px #282c34;
      margin-bottom: 12px;
  }
  .trbadge {
      padding: 1px;
      display: block;
      margin-right: 5px;
      margin-top: 5px;
      border-radius: 5px;
  }
  .trtags {margin-top:10px; margin-left: 7%;}

  h3 { font-size: 14px; }
 .resdiv { width: 98%;}
  .phweb { margin-right:1px;}

/*btn-warning btn-lg btn-link-warning */

.sboxdiv { margin-left: 2%; }
.sbox{
  width: 68%;
    float: left;
    margin-right: 3%;
    font-weight: 500;
    border: #282c34 1px;
  font-size: 14px;
}
.btn-lg {
  padding: 8px;
  height: 45px;
  font-size: 16px;
}

.p-3 { padding:5px !important; margin-top: 10px; }

.sbox-nav {

  float: left;
  /* margin-right: 3px;
  margin-left: 0px; */
  margin:0px;
  font-size: 12px;
  font-weight: 500;
  border: 1px #282c34;
  /* margin-bottom: 10px; */
  min-width: 185px;
  width: auto;

}

 .phdiv { margin-left:5px}
 .pname, .phitemdiv {font-size: 13px;}
 .pnl {font-size: 13px; font-weight: 700;}
 .phnum { margin-right:1px; margin-bottom: 5px;}
 .phitemdiv .fa {font-size: 12px;}
 .phitemdiv { min-width: 114px; min-height: 72px;
  margin-right: 3px;}

 .nmsg{ font-size: 14px; font-family: -apple-system, BlinkMacSystemFont,'Segoe UI', 'OpenSans', 'Tahoma', 'Geneva', Verdana, sans-serif; 
  padding: 2px; margin: 2px;
  }
.navitem { margin: 0px; padding: 0px; font-size: 11px; min-width: 40px;}
.logo { font-size: 17px; padding-right: 0px; margin: 0px; padding: 0px;}

.logo:hover { font-size:20px; font-weight: 700;}
.navitem:hover {font-size:13px; font-weight: 700;}


.side-right {
  width: 25%;
}
.side{
  width: 25%;
}
.middle{
  width: 50%;
}

}

/* Responsive layout - make the columns stack on top of each other instead of next to each other */
@media (max-width: 299px) {
  .side, .side-right {
    width: 100%;
  }
  .middle {
    display: none;
  }
}


body {
background: #343541;
color: #d8d8d8;
}

/*font-family: 'Open Sans';*/
/*padding: 3px !important;*/


.rsdiv {
color: #e8eaed;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
    border-radius: 25px;
    /* padding: 0px 10px 10px 10px; */
    margin: auto;
    margin-top: 15px;
    margin-bottom: 10px;
    /* max-width: 1000px; */
    /* border-radius: 20px; */
    /* opacity: 0; */
    /* transform: scale(0.99); */
    background: rgba(0, 0, 0, 0.4);
    padding: 15px 0px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px) brightness(0.7);
    border: 1px solid rgba(255, 255, 255, 0.2);
}
.rsdiv .p-3 {
display: flex;
    margin-top: 5px;
    margin-left: 15px; 
	margin-right: 15px;
    border-radius: 25px;
    padding: 8px !important;
    margin-bottom: 15px;
    background: linear-gradient(135deg, #3a3a3a, #2a2a2a);
	filter: contrast(1.1);
    vertical-align: middle;
    align-items: center !important;
}
.crd { 
margin-bottom: 0px !important;
border-radius: 0px;
border: 0px !important; 
 }

/*background-color: #1e1c22; #111 #2b2b2f; */

.crd-item {
	background: rgba(0, 0, 0, 0.5);    
    color: #d8d8d8; 
    padding: 10px 20px;
    /*border-radius: 21px !important;*/
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    backdrop-filter: brightness(0.5);
    border-bottom: 1px solid rgb(147 146 146 / 37%);
    /* opacity: 0; */
    /* transform: scale(0.99); */
	transition: background-color 0.3s, color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.crd-item:hover {
background-color: #3c3c3c !important; /* 3b3b3f */
color: #bfbfbf; /* d8d8d8 e8eaed*/
 box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
 transform: translateY(-5px); /* Example translation for depth effect */
}

.crd-item:hover .rounded-pill {
background-color: #3f3f3f !important; /*303030*/
}

.rtgtxt .badge {
color: #d8d8d8 !important;
}

.crd-item:hover .rtgtxt .badge {
color:#c8c8c8 !important;
}


/*007bff;*/
.crd a { 
color: #008bff;   
text-decoration: none;
transition: color 0.3s;
}

.crd-item:hover a { color: #749ac8; }

.rdiv {
width: 185px !important;
}

/* For Webkit browsers (Chrome, Safari, Edge) */
	  ::-webkit-scrollbar {
            height: 2px !important; /* Adjust the height of the scrollbar */
			width: 3px; /* Adjust the width as desired */
		}

      /* For Firefox */
        body {
            scrollbar-width: thin;
        }
		

/* For Firefox */
::-moz-scrollbar {
    width: 3px; /* Adjust the width as desired */
}

/* For Internet Explorer and Edge */
::-ms-scrollbar {
    width: 3px; /* Adjust the width as desired */
}

.newheader nav ul { display: flex; }

#footer nav ul { display: block; }

.checked {
color: #ff7f00!important
}
@media screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1200px) {
.rsdiv { margin-left: 5%; }
.topdiv { min-height: 545px; }
.trtags { margin-left: 10%; }
}

@media screen 
  and (min-width: 1200px) {
.topdiv { min-height: 600px; }
.trtags { margin-left: 16%; }

}

.btnftrimg { width: 30px; margin-left: -7px; }

#app { min-height: 450px }




.bar-5 { background-color: #0d703e !important; }
.bar-4 { background-color: #0d6efd !important; }
.bar-3 { background-color: #32a3d1 !important; }
.bar-2 { background-color: #ff8c00 !important; }
.bar-1 { background-color: #96242f !important; }

.lbl-primary {
   background-color: #185a87!important;
    color: #e9eeff !important;
    border-radius: 5px;
    margin-top: 10px;
    padding: 8px !important;
    box-shadow: 0 0 2px rgba(0,0,0, 0.5);
}
	
.pwdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 240px;
	margin-bottom: 8px;
	}
	
.rounded-pill { 
    background-color: #454545 !important; /* 3b3b76 444746 #f7f7ff */
    color: #bfbfbf !important; /*#0288D1*/
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    max-width: 300px;
    border: 1px solid #333; /*#0288D1;*/
    text-align: left;
}
.rounded-pill:hover { background-color: #212121 !important; color: #e8eaed !important; border: 1px solid #212121;}

.sbtn:hover { 
  transform: translateY(-2px); /* Add a subtle lift on hover */
}

.sbtn:active {
  transform: translateY(2px); /* Add a press-down effect when active/clicked */
}

/*.progress .bg-warning {
background-color: rgba(var(--bs-secondary-rgb),var(--bs-bg-opacity)) !important;
}

.progress .bg-danger { 
background-color: rgba(var(--bs-secondary-rgb),var(--bs-bg-opacity)) !important;
}

.progress .bg-success { 
background-color: rgba(var(--bs-secondary-rgb),var(--bs-bg-opacity)) !important;
}

.progress .bg-primary {
background-color: rgba(var(--bs-secondary-rgb),var(--bs-bg-opacity)) !important;
}

.progress .bg-dark {
background-color: rgba(var(--bs-secondary-rgb),var(--bs-bg-opacity)) !important;
}
*/

.checked {
    color: #FF9529 !important;
}

.progress .bg-warning {
background-color: rgba(52,53,65,var(--bs-bg-opacity)) !important;
}

.progress .bg-danger { 
background-color: rgba(52,53,65,var(--bs-bg-opacity)) !important;
}

.progress .bg-success { 
background-color: rgba(52,53,65,var(--bs-bg-opacity)) !important;
}

.progress .bg-primary {
background-color: rgba(52,53,65,var(--bs-bg-opacity)) !important;
}

.progress .bg-dark {
background-color: rgb(52,53,65,var(--bs-bg-opacity)) !important;
}
.progress .bg-secondary {
background-color: rgb(52,53,65,var(--bs-bg-opacity)) !important;
}



.wldiv {padding: 1px !important;
background-color: #343541 !important;
/*max-height:40px;*/}


.phdiv { background-color: #2a3439 !important; /*#929595*/
color: #e8eaed !important;
overflow: auto !important; 
align-items: start !important;
margin-left: 0px !important;
margin-bottom: 70px;
}

.phname {
display: block;
text-align: left;
white-space: nowrap;
overflow: hidden !important;
text-overflow: ellipsis;
min-width: 120px;
margin:5px;
color:#e8eaed;
}

.pnl { color: #e8eaed; margin-top: 5px;}

.phitemdiv {
	margin-bottom: 0px;
	margin-top: 0px;
	padding: 0px;
	min-width: 130px !important;
	min-height: 0px !important;
	margin-right: 0px !important;
	justify-content: space-between;
	flex-direction: column;
	background-color: #2a3439 !important;
  border-bottom-color: #000 !important;
  border-bottom-style: none !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  margin-bottom: 0px !important;
  transition-duration: 0.3s;
}

/*border-bottom: 3px solid #1e2f45 !important;
border-radius: 5px; 
background-color: #343541 !important; */

.klink { 
margin: 1px !important;
padding: 2px!important;
/*min-width: 150px;*/
text-align: left;
font-size: 16px; 
color: #d8d8d8; /*#D1D5DB;*/
/*border-radius:8px;*/
}

.klink .btn {  
    border-radius: 5px; 
	background-color: #1e1e1e; /* 2f2f2f 444746 #343541;*/
    color: #d8d8d8;
    padding: 5px !important; 
    border: 0px solid #424242;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 0px 3px 0px 0px;
	box-shadow: 0 0 4px rgba(0,0,0,0.35);
    transition-duration: 0.3s;
}


.klink .btn:hover {     
background-color: #585858; /*#f5f5f5; color: #333; */
border-radius: 5px;
color: #e8eaed;
border: 2px solid #888888; /* #343541; */  
}
	
.container-fluid.text-center { 

margin-left: 5%;
text-align: left!important;

}
.klink:hover {

font-weight: initial !important;
font-size: initial !important;
}
.phitemdiv:hover {
border-radius: 5px !important;
border-width: 1px !important;
border-style: solid !important;  
border-color: #343541 !important;
background-color: #f5f5f5 !important;
color: #333 !important;
}

.phitemdiv:hover div{
background-color: #f5f5f5 !important;
color: #333 !important;
font-weight: 700;
}


.phitemdiv:hover a{
background-color: #f5f5f5 !important;
color: #333 !important;
}
.phname:hover { background-color: #f5f5f5 !important;
color: #333 !important;
}

#hero { background-color: #343541; color: #D1D5DB; font-size: 16px; text-align:left; }

.annmsg {  
  font-weight: 500;
  margin-top: 0px;
  background-color: #1c1e22; /*#f1fbfc #202124; #121212;*/ /*rgb(0, 115, 187);*/
  margin-left: 0px;
  padding: 3px;
  line-height: 1.4;
  margin-right: 0px;
  padding-bottom:5px;
  color: #e8e8e8; /*#212121; #f9f9f9; e5e5e5 d2d2d2 #f9f9f9;*/
  font-size: 16px;
  top: 0;
  /*position: fixed;*/
  text-align: center;
box-shadow: 0 0 2px 2px rgba(0,0,0,0.5);
max-height:40px;
overflow: auto;
    /*white-space: nowrap; */ /* Prevent text from wrapping */
}

.annmsg  a { color: #007bff; /*#08c;*/ }
.annmsg span { margin-left: 10px; margin-right: 5px; text-align: center;}


h2 { font-size: 18px; font-weight: 400; }
.mainmsg  { font-size: 18px;
margin-bottom: 15px;
text-align: left;
margin-top: 15px;
margin-left: 12%;
margin-right: 12%;
line-height:1.5;
font-weight: 400;
 }

.hlp { text-align: left; /* text-decoration: underline; */ 
color:#333; font-size: 18px; font-weight: 300;
margin-left: 0px; line-height:1.5;
background: #f1fbfc; 
  padding: 3px;
  box-shadow: 0 0 2px 2px #0a2c4070;
  width: 425px;
  border-radius: 5px;}


.navbar.bg-warning { 
/*background-color: #515151 !important;*/ 
/* #444746 #f1fbfc #343541 !important;*/ 
}

.ftrdiv { display: none; }

.img-logo { border-radius: 5px; }

.navitem { color: #D1D5DB; }

/*border-color: #183c52 !important; border-width: 1px !important;
 background-color: #212529 !important; 
 color: #d8d8d8 !important; 
 border: 2px solid #424242 !important; 
 box-shadow: rgba(0,0,0,.4) 0 2px 4px,rgba(0,0,0,.3) 0 7px 13px -3px,rgba(0,0,0,.2) 0 -3px 0 inset;

*/

.sbtn { 
  background: linear-gradient(180deg, #1a1a1a, hsl(228, 5%, 8%)) !important;
  color: #e8e8e8 !important;
  border: 0px solid #424242 !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

/*background-color: #f5f5f5; color: #333; border-color: #343541;*/

.sbtn:hover {  
 color: #e8eaed !important;
 border-radius: 5px;
 background: #585858 !important; 
 border: 2px solid #888888 !important; 
 }


/* footer */
.ftr-wrap { }
	.ftr-container {
			width: 100%;
			background-color: #343541;
			color: #D1D5DB;
			padding: 5px;
			display: flex;
			}
			
						/*border-bottom: #1d5481; border-bottom-style: solid; border-bottom-width: 2px;*/
		#footer {
			 width: 100%;
			  padding: 0px;
			  box-sizing: border-box;
			  display: flex;
			  flex-wrap: wrap;
			  flex-direction: row;
			  height: min-content;
			  /*position: fixed;
			  left: 0;
			  bottom: 0;*/
			}
		.col {
			flex: 1;
			margin-right: 20px;
			margin-left: 20px;
			min-width:185px;
			text-align: right;
		}
		.col:last-child {
			margin-right: 0;
		}
		.col h3 {
			font-size: 18px;
			font-weight: 500;
			margin-bottom: 10px;
			margin-top: 10px;
			margin-right: 20px;
			padding: 5px;
			text-align: left;
			color: #D1D5DB;
		}
		.col ul {
			list-style: none;
			padding: 0;
			margin: 0;
			text-align: center;
		}
		.col ul li {
			margin-bottom: 8px;
		}
		.col ul li a {
			color: #dbd5db;
			text-decoration: none;
		}
		.ftrimgdiv { /*margin-right:65px;*/ /*margin-top: 25px;*/ margin-left:0px; 
background-color: #343541;
margin-left: 0px;
border: 0px; }
		
		.btmimg { border-radius: 5px !important;
display: block; 
width: 160px; margin-top: 20px; margin-bottom: 10px;}
	
        .ftrnavitem {    padding: 2px;
    margin-bottom: 5px; font-weight: 400; color: #bfbfbf; text-align: left !important;}


.mnitem { display: flex;
    flex-direction: column;
    align-items: center; height:90px; margin-top:14px; margin-bottom:5px; }
	
.menu li {   list-style: none; }

.menu nav { display: flex; }

#footer li { list-style: none; }
	
.slct { margin-top: 0px !important; }

.lbl { font-size:12px; margin-top: 5px; color: #ECECF1; }
	

.menu .lbl { max-width: 52px; }


h1 { font-size: 30px; }
p { font-size: 18px; line-height:1.5;}

.tdiv li { list-style: none; }

.lbl { font-size: 12px; text-align:center; }

/* results loading */
.search-results {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.search-result {
  display: flex;
  margin-bottom: 20px;
  width: calc(50% - 10px);
}

.result-thumbnail {
  background-color: #e1e1e1;
  width: 130px;
  height: 80px;
  margin-right: 15px;
  border-radius: 3px;
  animation: loading 1.2s ease-in-out infinite;
}

.result-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 145px);
}

.result-title {
  background-color: #e1e1e1;
  height: 25px;
  margin-bottom: 10px;
  border-radius: 3px;
  animation: loading 1.2s ease-in-out infinite;
}

.result-description {
  background-color: #e1e1e1;
  height: 18px;
  margin-bottom: 5px;
  border-radius: 3px;
  animation: loading 1.2s ease-in-out infinite;
}

@keyframes loading {
  0% {
    opacity: 0.4;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}

.clogo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 107px;
  background-color: #f2f2f2;
}

.clogo div {
  width: 100px;
  height: 100px;
  background-color: #ffc107;
  border-radius: 50%;
  color: #000;
  font-size: 53px;
  font-weight: 700;
  text-align: center;
  line-height: 100px;
}

.amain, .header {
font-weight: 600;
line-height: 1.25em;
 text-transform: capitalize; /* Convert text to correct case */
}

.badge {
display: inline-block;
padding: 2px;
margin: 2px;
line-height: 1.35em;
padding-left: 6px;
white-space: normal; /* or white-space: pre-wrap; */
 }

.avatar { 
width:40px;
height: 40px;
object-fit: cover; }

/* phone screens */

@media screen and (max-device-width: 767px) {

#app { min-height:300px; }

.rounded-pill {   max-width: 175px; } 

.pwdiv {
   max-width: 185px; }


.avatar { 
width: 36px;
height: 36px;
object-fit: cover; }

.rsdiv { margin-left: 5px; }
.badge {
font-size: 11px;
white-space: normal; /* or white-space: pre-wrap; */
 }

/*min-width: 160px;
width: max-content;
text-align: left;*/
/*border-bottom: 2px solid #1e2f45 !important;*/

.klink { 
 margin: 2px !important;
  padding: 1px !important;
  font-size: 16px; 
}
.trtags { margin-left: 2% !important; }

.trbadge {
   margin-right: 10px !important; 
	}


.container-fluid.text-center { 

margin-left: 8%;
text-align: left!important;

}

#hero { font-size: 12px; text-align:left; }
.annmsg {  
  font-weight: 500;
  margin-top: 0px;
  margin-left: 0px;
  padding: 1px;
  margin-right: 0px;
  padding-bottom: 5px;
  font-size:14px;

}
.annmsg span { margin-left: 5px; margin-right: 5px; text-align: center;}


.mainmsg  { font-size: 15px;
margin-bottom: 10px;
margin-top: 20px;
text-align: left;
margin-left: 8%;
margin-right: 8%; }

.hlp { /*color:#D1D5DB;*/
/*margin-left: 5px;*/
width: 100%;
 font-size: 14px; 
 font-weight: 400; 
 line-height:1.4;}

h2 { font-size: 15px; font-weight: 400;}

    .menu {
      flex-wrap: wrap; }

.message-icon { width: 24px; height: 24px; margin-right: 6px; margin-left: 6px; }

h1 { font-size: 21px; }
p { font-size: 16px; line-height:1.5; }

/*body { font-size 14px; }*/

.newheader .menu button { margin: 0 4px; }

.newheader .menu button i { padding: 2px; }

.imgdiv { /*margin-left: 110px; margin-right: 110px;*/ margin-left: 10px; }
.img-logo{/*height:72px;*/}
  .linkdiv ul { margin-left: 15px; }

.amain, .header {
font-weight: 600;
line-height: 1.2;
}
}


/* override font */
body { font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Open Sans','Noto Sans',Roboto,Lato,Ubuntu,'Source Sans Pro',Oxygen,'Fira Sans','PT Sans',Cantarell,'Helvetica Neue',Tahoma,Geneva, Arial,sans-serif; -webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale !important; 
      text-transform: capitalize;
}

.trbadge {
	background-color: #1a1a1a !important;
    color: #ffc107 !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08) !important;
  padding: 3px;
}

.trbtn {
	color: #d8d8d8 !important;
    border: 0px !important;
    padding: 5px !important;
}

.trbadge:hover {
  background-color: #444746 !important;
  border: 1px solid #888888 !important;
  /*color: #e8eaed;*/
}

.page-link {
    background-color: #b1b1b1;
    color: #111;
}

.active>.page-link, .page-link.active {
 background-color: #1a1a1a !important;
}

 .newheader {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #343541;
  color: #d1d5db;
  overflow: auto;
  min-width: 405px;
  padding-bottom: 10px;
}
.newheader .menu {
  display: inline-flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
   margin-top: 15px;
  margin-left: 10px;
  margin-bottom: 12px;
  width: 100%;
}

@media screen and (min-width: 1000px) {
  .menu {
    margin-right: 4vw;
  }
}
@media screen and (min-width: 578px) {
  .menu {
    margin-right: 2vw;
  }
  
}
 
@media screen and (min-width: 1000px) {
  .imgdiv {
    margin-left: 8vw;
    margin-right: auto;
  }
}
@media screen and (min-width: 1000px) {
  .imgdiv {
    margin-right: 30vw;
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 426px) {
  .hlp {
    width: 100%;
  }
  
}

.img-logo {
  height: 84px;
  vertical-align: middle;
  width: auto !important;
  margin-left:0px !important;
}

@media screen and (max-width: 767px) {
.sbox { margin-bottom: 20px; }

.sbtn { margin-left: 9%; }
.img-logo { height: 72px !important; width: 120px !important; }

.tline {
flex-direction: column; 
}

.rdiv {
width: 105px !important;
}
}

.imgdiv {
  background-color: #ffc107;
  text-align: left;
  margin-right: 100px;
  margin-bottom: 12px;
  border: 4px #ffc107 solid;
  border-radius: 5px;
  
}
.menu nav {
  display: flex;
}
.menu li {
  list-style: none;
}
.mnitem {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.menu .lblselect {
  font-weight: 700;
}
.menu .lbl {
  font-size: 12px;
  text-align: center;
  margin-top: 5px;
  color: #ececf1;
}

.newheader .menu button {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 10px;
  margin: 0 10px;
    margin-top: 0px;
  font-size: 18px;
  color: #d1d5db;
  cursor: pointer;
  transition: all .3s;
  min-width: 56px;
  }
.btnimg {
  width: 36px; /*50px */
  /*border-radius: 50%;*/
  }
#aiimg {
  width: 50px; 
  height: 50px;
  }
  
 .newheader .selected { 
  border: 3px solid #ffc107 !important;
  border-radius: 50% !important;
  margin-top: -8px !important;
  background: #004466 !important;
}


.navbar-expand .navbar-nav { display: none; }
.navbar-brand .imgdiv { display: none; }
.wldiv { display: none !important; }
.phdiv { display: none !important; }
.btmimg { padding-right: 0px !important; }

.aimenubtn {
display: flex; align-items: center; height: 30px; width: 35px; justify-content: center;
object-fit: cover;
}

.mnitem i { font-size:1.3em; padding: 3px; /*color: #FF9500FF; #08c;*/ }

.selected i {
font-size: 1.5em;
/*color: #007bff;*/
padding: 10px !important;
}

.mascothdr {
  width: 150px;
  height: 150px;
  margin-left: 5px;
  border-radius: 50%; /*15px;*/
  box-shadow: 0 0 4px 8px #1e1423d9; /*  0 0 4px 6px #212121;*/
  float: right;
}
.ai-tooltip {
  position: relative;
  font-size: 16px;
}

/*background-color: #7d7d7d; box-shadow: 0 3px 3px 3px #777777c4;*/
  /*border-top-left-radius: 0; border-top-right-radius: 0; border: 2px solid rgba(0, 0, 0, 0.89);*/
  /* border-bottom-left-radius: 16px; border-bottom-right-radius: 16px;  */
  /*border: 2px solid #777;*/
  /*padding: 10px; */
  /*background-color: #303030; 303035 #F2F2F7FF #f8fcff;*/

.hero { 
  margin-bottom: 0px; 
  border: 0px;
  border-radius: 0px !important;
  color: #bfbfbf;  /* #2C2C2EFF #45545b;*/
  background: linear-gradient(135deg, #2c2c2c, #1a1a1a);
}

.hlpdiv { 
margin: 10px;
margin-left: 20px;
}
.tctr { text-align: center; 
  background: #111;
  /*box-shadow: 0 0px 2px 4px #000000d6;*/
  width: fit-content;
  border: 2px solid #18232ddb;
  color: #ffffffdd; /* #EBEBF599 */
  padding: 7px;
  border-radius: 15px;
  /*box-shadow: 0px 1px 0px 2px #000000f0;*/
  box-shadow: 0 0 1px 1px #555353f0;

  width: fit-content;
  display: flex;
  margin-left: auto;
  margin-right: auto;
 }
 .herolbl { 
 color: #ffc107;
 font-weight: 700;
 margin-left: 0.28em;
}
 
 .subhero {
 font-weight: 500;
 line-height:1.2em;
 margin-top: 20px;
 margin-bottom: 10px;
 }
 
 .hlpnum { display: inline-block; font-weight: 700; }
 .newheader .menu button:hover{background-color:#fff;color:#333; }
 .hlptext {
  display: inline-block;
  margin-right: 10px; /* Add margin between text and number */
  font-weight: 600;
  width: 205px;
}
.hlpdiv h6 {   margin-bottom: 0.8em; }

p {
    display: block;
    -webkit-margin-before: 1__qem;
    -webkit-margin-after: 1__qem;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
}

.topmast{ height: 0px; }

.rsdiv { min-height: 475px; }

.ftrnavitem span, i{ /*color: #007bff;*/ }

.tctr { font-size: 1.1em; font-weight: 400; line-height: 1.5em; }
 .subhero p { font-size:1em; }

.lfnumhdr h6 { font-weight: 500; }

.modal-container, .topdiv h2 { font-size: 1.5rem; font-weight: 500; }

.modal-container { background: #343541; color: #d8d8d8; }

.topdiv { margin-top: 2em; }

@media screen and (max-width: 512px) {

.hlpdiv h6 {   margin-bottom: 0.4em; }
.hlpdiv { display: flex; }
.hlpnum, .hlptext { font-size: 14px; width: auto; }

 .subhero p { font-size:12px; font-weight: 600; }
 .tctr { font-size: 18px;}
 
 .imgdiv { margin-left: calc(50vw - 60px); }
 
 .mascothdr { width: 100px; height: 100px; }
 
 .hlpdiv { margin: 0px; }
 
 .newheader .menu button { margin: 0 4px; }
 .trbadge {
   margin-right: 8px !important; 
	}
}

@media screen (min-width: 380px) and (max-width: 512px) {
.subhero { margin-bottom: 25px; }
}

#socli { display: flex; }

#socli a { margin-right: 10px; }

/* Dark Input field styles */

 


.sbox, .sbox-nav { border: 0px !important; color: #d8d8d8 !important; }

.css-b62m3t-container  {
color: #d8d8d8 !important;
}

.css-b62m3t-container * {
    color: inherit !important;
}


.css-139q9t5-control, .css-e8ecmk-control, .css-rokqki-control, .css-wgm1s6-control {
background-color: #2f2f2f !important;
color: #d8d8d8 !important;
border: 0px !important;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add box shadow */
}

.css-139q9t5-control  {
height: 52px !important;
}

#react-select-2-listbox, #react-select-3-listbox {
background-color: #2f2f2f !important;
color: #d8d8d8 !important;
}

.css-1n7v3ny-option {
background-color: rgb(6 17 33) !important;
}

/*#react-select-2-listbox div:hover {
background-color: #3b3b3f !important;
} 
#react-select-3-listbox div:hover {
background-color: #3b3b3f !important;
}*/

/*.css-ackcql * {
 background-color: inherit !important;
 }
 
 .css-1d8n9bt * {
 background-color: inherit !important;
 }
 */
 
 #searchdiv li {
 list-style: none;
    display: inline-block;
    font-size: 16px;
    margin-right: 10px;
    margin-left: 0;
    margin-bottom: 15px;
    text-align: left;
    width: 261px;
    /*background: #343541; color: #d9d8d8;*/
    padding: 5px;
    text-decoration: none;
    border-radius: 7px;
    box-shadow: 0 0 1px #574f4f;
	border-radius:12px; 
	color: #e8eaed; 
	font-size: 14px; 
} 
#srdivhdr { min-width: 351px; 
margin-top: 0.5em; 
} 

.col h3 { margin-top: 20px; } 

/* old btn link colors */
/* background: linear-gradient(180deg, hsl(228, 5%, 16%), hsl(228, 5%, 8%)) !important; */
  /* background-color: hsl(228, 5%, 15%) !important;  #28292a */
 
.aireshdr {
    margin-top: 5px;
    line-height: 2em !important;
    font-weight: 600;
    border-left: 18px ridge #607D8B;
    color: #d5d8db;
    background-color: #091541;
    padding: 10px;
    border-radius: 7px;
    font-size: 20px;
    box-shadow: 0 2px 3px #2623234f;
}	
#searchdiv li { 
  border: 0px !important;
  background-color: #1c1e22 !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08) !important;
}
#searchdiv a { 
background: none !important;
text-decoration: none;
color: #e8eaed;
}

#searchdiv a::before {
    content: "•";
    font-size: 18px;
    font-weight: 900;
    color: #008bff;
    text-shadow: #007bff 0 0 3px;
    margin: 0 8px;
	}
#trending-services .trending-slink { 
  border: 0px !important;
  background-color: #1c1e22 !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08) !important;
  border-radius: 9px; 
  margin-left: 0px;
  padding: 7px;
  margin-right:10px;
  margin-bottom: 10px;
  margin-top: 0px;
  color: #ffc107; 
}


#trending-services a { 
 color: #e8eaed;
 font-size: 14px;  
 text-decoration: none;

}

#trending-services .trending-slink:hover { 
  cursor: pointer;
  transition: background-color 0.3s;
    box-shadow:0 0 10px rgba(0,0,0,.56); 
    background: #444746 !important; 
}

#trending-services .trending-slink a:hover { 
box-shadow: none !important;
color: #bfbfbf !important;
}

#trending-queries .trending-link:hover { 
  cursor: pointer;
  transition: background-color 0.3s;
    box-shadow:0 0 10px rgba(0,0,0,.56); 
    background: #444746 !important; 
}

#trending-queries .trending-link a:hover { 
box-shadow: none !important;
color: #bfbfbf !important;

}


#searchdiv li:hover { 
  cursor: pointer;
  transition: background-color 0.3s;
    box-shadow:0 0 10px rgba(0,0,0,.56); 
    background: #444746 !important; 
}

#searchdiv li a:hover {
  color: #bfbfbf !important;
box-shadow: none !important;
}

.trending-section h5 {
  position: relative; /* Position relative for ::before pseudo-element */
  margin-left: 2px;
  padding-left: 12px;
  line-height: 130%;
  margin-bottom: 1rem;
}

.trending-section h5::before {
  content: ''; /* Empty content */
  position: absolute; /* Position absolutely relative to h5 */
  top: 0; /* Align at the top */
  left: 0px; /* Adjust the left position for the line */
  width: 5px; /* Line width */
  height: 100%; /* Full height */
  background-color: #88225B; /* Line color */

}

#searchdiv h5 {
  position: relative; /* Position relative for ::before pseudo-element */
  margin-left: 2px;
  padding-left: 12px;
  line-height: 130%;
}

 #searchdiv h5::before {
  content: ''; /* Empty content */
  position: absolute; /* Position absolutely relative to h5 */
  top: 0; /* Align at the top */
  left: 0px; /* Adjust the left position for the line */
  width: 5px; /* Line width */
  height: 100%; /* Full height */
  /*background-color: #2046b9; /* Line color */ 

}

#servicediv {
	margin-left: 3%;
}
.nlul {
  border-left: 8px solid #5b0000; /* 10px ridge #f32961; c30303  b32e2e*/
  display: flex;
  padding: 0px !important;
}
.nlserv {
  padding: 10px !important;
  /*border-left: 15px solid #b32e2e !important;*/
  margin: 8px !important;
}
.nlserv a::before { color: #10ca76 !important; }

/* #df0404 */

/* Default color for ::before pseudo-element */
.heading::before {
  content: '';
  position: absolute;
  display: inline-block;
  left: 0px;
  top: 0;
  width: 5px;
  height: 100%;
  margin-right: 10px;
}



/* Custom colors based on data-type attribute */
.heading[data-type="cases"]::before {
  background-color: var(--cases-color);
}
.heading[data-type="docs"]::before {
  background-color: var(--docs-color);
}

.heading[data-type="books"]::before {
  background-color: var(--books-color);
}

.heading[data-type="topics"]::before {
  background-color: var(--topics-color);
}

.heading[data-type="search"]::before {
  background-color: var(--search-color);
}

.heading[data-type="city"]::before {
  background-color: var(--city-color);
}

.trending-container {
  display: flex;
  flex-direction: row; /* By default, display items horizontally */
  flex-wrap: wrap; /* Allow items to wrap to the next line if needed */
}

.trending-section {
  flex: 1; /* Each section occupies equal space */
  margin-right: 10px; /* Add some margin between sections */
  margin-bottom: 10px; /* Add some margin between sections */
}

@media (max-width: 768px) {
  .trending-container {
      flex-direction: column; /* Switch to vertical layout on smaller screens */
  }

  .trending-section {
      margin-right: 0; /* Remove right margin */
  }

  .trending-section h5 {
    /*text-align: center;*/
}
}


#searchdiv ul {
padding: 1rem;
}

.linkdiv {
background-color: #3e4049 !important; /*#2f373e #132027*/
margin: 1% !important;
border-radius: 15px !important;
color: #f8f8f8;
display: inline-flex;
padding: 10px; 
}

#servicediv {
margin-left: 5px !important;
}

.mnitem button:not(.selected) {
background-color: #111 !important;
}

.data-container {
  /* Ensure appropriate styling */
}

.map-container {
  /* Ensure appropriate styling */
  height: 300px; /* Adjust as necessary */
}

/* Additional styles as needed */

.leaflet-popup-content {
    width: 315px !important;
    margin: 12px !important;
    margin-right: 20px !important;
    margin-bottom: 20px !important;
}

.mappop {
    max-height: 215px; 
    overflow: auto;
	font-family: -apple-system,system-ui,BlinkMacSystemFont,'Segoe UI','Open Sans',Roboto,Lato,Tahoma,Ubuntu,'Noto Sans',Oxygen,Cantarell,'Helvetica Neue',sans-serif;
}

.mappop h6 {
  font-size: 16px;
  font-weight: 400;
}

.mappopitem {
 font-size: 14px;
} 

.leaflet-container a {
	color: #008bff !important;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
 background: #2f2f2f !important;
  color: #d8d8d8 !important;
}

.marker-cluster-small, .marker-cluster-medium, .marker-cluster-large {
  background-color: rgba(0, 123, 255, 0.6) !important;
  /*border: 2px solid rgba(0, 123, 255, 0.8) !important;*/
  color: #fff;
}

/*background-color: rgba(255, 255, 255, 0.6) !important;*/

.marker-cluster-small div, .marker-cluster-medium div, .marker-cluster-large div {
  background-color: rgba(0, 123, 255, 0.75) !important;
  border-radius: 50%;
}

.klink { 
margin: 2px !important;
padding: 4px!important;
/*min-width: 150px;*/
text-align: left;
font-size: 16px; 
color: #d8d8d8; /*#D1D5DB;*/
/*border-radius:8px;*/
}

.klink .btn {  
    border-radius: 5px; 
	background-color: #1e1e1e; /* 2f2f2f 444746 #343541;*/
    color: #d8d8d8;
    padding: 5px !important; 
    border: 0px solid #424242;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 0px 3px 0px 0px;
	box-shadow: 0 0 4px rgba(0,0,0,0.35);
    transition-duration: 0.3s;
}


.klink .btn:hover {     
background-color: #585858; /*#f5f5f5; color: #333; */
border-radius: 5px;
color: #e8eaed;
border: 2px solid #888888; /* #343541; */  
}

.prfstr {
color: #ffc107;
}

.mappopitem {
text-shadow: 1px 1px #1a1a1a;
}

.modal-container {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    max-width: 400px;
    max-height: calc(100vh - 40px);
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    overflow: auto;
}

.navbar.bg-warning {
	background-color: rgba(255, 255, 255, 0.12) !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.tctr { margin-top: 20px; }


/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
